import React from 'react';
import './Footer.css';
import { SocialIcon } from 'react-social-icons';

export const Footer = () => {
    return (
        <div className="container-footer">
            <div className="footer-c">
                <h5 >
                    ABOUT WECONNECT
                </h5>
                <p className="footer-paragraph">
                    To build a community of people entering the work force by collaborating and working towards achieving similar goals.
                </p>
            </div>
            <div className="footer-c">
                <h5>
                    SOCIAL
                </h5>
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/weconnectcorporation" target="_blank" rel="noreferrer">
                        <SocialIcon network="linkedin" bgColor="#EDEFF2" fgColor="#000000" />
                        <span className="span-style">Linkedin</span>
                    </a>
                    <a href="https://www.instagram.com/weconnect.consult/" target="_blank" rel="noreferrer">
                        <SocialIcon network="instagram" bgColor="#EDEFF2" fgColor="#000000" />
                        <span className="span-style">Instagram</span>
                    </a>
                    <a href="https://twitter.com/WeConnect_Corp" target="_blank" rel="noreferrer">
                        <SocialIcon network="twitter" bgColor="#EDEFF2" fgColor="#000000" />
                        <span className="span-style">Twitter</span>
                    </a>
                </div>
            </div>
        </div>
    )
}