import React from 'react';
import '../../stylesheet/style.css';
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import Lottie from "react-lottie";
import animationData from "../../components/lotties/lottie";

export const Home = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };

    return (
        <div className="background-image">
            <Container className="home-container">
                <Row>
                    <Col md="6" sm="6" className="pb-4">
                        <h1 className="main-heading">THIS IS <br />WE CONNECT</h1>
                        <h5 className="home-subheading">SOLUTIONS FOR A BETTER FUTURE</h5>
                        <a href='/contact-us'><Button className="btncolor">Contact Us</Button></a>{' '}
                        &nbsp;&nbsp;
                        <a href='/services'><Button className="btncolor">Our Services</Button></a>{' '}
                    </Col>

                    <Col md="6" sm="6" className="text">

                        <Lottie className="lottie1" options={defaultOptions} />

                    </Col>
                </Row>
            </Container>
        </div>

    )
}