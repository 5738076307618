import React, { Component } from 'react';
import '../../stylesheet/style.css';
class ContactUs extends Component {
    render() {
        return (
            <div className="container">
                <center>
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSd9Yxzih392XzSBP4a2fddzdjvbSfNq35Dnp3LZDLs7jTK3KA/viewform?embedded=true"
                        title="form"
                        width="100%"
                        height="939"
                        frameborder="0"
                        marginheight="0"
                        marginwidth="0"
                    >Loading…</iframe>
                </center>
            </div>
        );
    }
}

export default ContactUs;