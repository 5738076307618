
import { Home } from '../pages/Home';
import { Team } from '../pages/Team';
import ContactUs from '../pages/ContactUs';
import { WhatWeDo } from '../pages/WhatWeDo';
import { Projects } from '../pages/Projects';

export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links',
        component: Home
    },
    {
        title: 'Team',
        url: '/team',
        cName: 'nav-links',
        component: Team
    },
    {
        title: 'Services',
        url: '/services',
        cName: 'nav-links',
        component: WhatWeDo
    },
    {
        title: 'Clients',
        url: '/clients',
        cName: 'nav-links',
        component: Projects
    },
    {
        title: 'Contact Us',
        url: '/contact-us',
        cName: 'nav-links-button primary',
        component: ContactUs
    },
]