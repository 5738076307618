import React from 'react';
import {
    Card, Row, CardBody, CardTitle, CardSubtitle,
    Col, Container, CardImg, CardText,
} from 'reactstrap';
// import team from '../../assets/imgs/team.png';
import aman from '../../assets/imgs/Aman-Mujeeb.jpeg'
import sufian from '../../assets/imgs/Sufian-Sheikh.jpeg'
import '../../stylesheet/team.css';
export const Team = () => {

    return (
        <div className="team-container">
            <Container>
                <Row>
                    <Col>
                        <h1 className="team-heading1">Our Team</h1>
                        <h4 className="team-subheading1">Our team consists of people from diverse backgrounds who have made their way up by acquiring certain set of skills. Our goal now is to guide and mentor the youth that share the same interests.</h4>
                    </Col>
                </Row>
                <Row >
                    <Col xs="12" sm="4" className="team-container-card">
                        <div >
                            <Card>
                                <CardImg top width="100%" src={aman} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle tag="h5">Mohammed Aman Mujeeb</CardTitle>
                                    <CardSubtitle tag="h6" className="mb-2 text-muted">Founder </CardSubtitle>
                                    <CardText>As the founder my role is to guide and motivate people by creating the initial foundation of the team. Working towards structuring the performance and tracking the progress of each individual working with the our platform. Providing better solutions for the community is my main goal and growing along
                                        with each other is our mission.</CardText>
                                    {/* <Button>Button</Button> */}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col xs="12" sm="4" className="team-container-card">
                        <div>
                            <Card>
                                <CardImg top width="100%" src={sufian} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle tag="h5">Sufian Sheikh</CardTitle>
                                    <CardSubtitle tag="h6" className="mb-2 text-muted">CTO</CardSubtitle>
                                    <CardText>As the CTO, I offer technical advice to our clients while managing a variety of teams during the process of product development. From brainstorming ideas to creating a framework that includes the programming and prototyping of the product, I transform our clients' dreams into reality, tailoring them to perfection.</CardText>
                                    {/* <Button>Button</Button> */}
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    {/* <Col xs="12" sm="4" className="team-container-card">
                <div>
                    <Card>
                        <CardImg top width="100%" src={team}alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">Person 3</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted"> Subtitle</CardSubtitle>
                        <CardText>Small description about team members</CardText>
                        {/* <Button>Button</Button> 
                        </CardBody>
                    </Card>
                    </div>
                </Col> */}
                </Row>
            </Container>
        </div>
    )
}