import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

import { NavBar } from './components/NavBar';
import { MenuItems } from './components/NavBar/MenuItems'
import { Footer } from './components/Footer/Footer';

function App() {
  return (
    <Router>
      <NavBar />
      <Switch>
{
  MenuItems.map((item, index) => {
    return (<Route path={item.url} exact component={item.component} />)
  })
}
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
