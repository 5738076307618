import React, { useState } from 'react';
import { Button, Modal, Carousel, CarouselItem } from 'react-bootstrap';
import sparkles from '../../assets/imgs/sparkles.png';

export const Projects = () => {

    // modal functions
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //  carousel functions
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div style={{ height: '90vh' }}>
            <h3 style={{
                paddingTop: 40,
                color: '#000',
                fontSize: '30px',
                fontWeight: 300,
                textAlign: 'center',
                letterSpacing: '5px',
            }}>Clients</h3>
            <p style={{
                paddingTop: 10,
                color: 'rgb(103, 103, 112)',
                fontSize: '30px',
                fontWeight: 300,
                textAlign: 'center',
                letterSpacing: '5px',
            }}>HERE IS AN INSIGHT TO OUR CLIENTS</p>
            <div style={{ display: 'flex', flexFlow: 'row wrap', padding: '30px', justifyContent: 'space-around' }}>
                <Carousel variant="dark" activeIndex={index} onSelect={handleSelect}>
                    <CarouselItem>
                        <div style={{ height: '50vh', width: '50vh', margin: 10, padding: '30px' }}>
                            <center>
                                <img src={sparkles} height="250vh" width="250vh" alt="sparkles international school logo" />
                                <Button variant="info" style={{ color: 'white', margin: '10px' }} onClick={handleShow} >LEARN MORE</Button>
                            </center>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div style={{ height: '50vh', width: '50vh', margin: 10, padding: '30px' }}>
                            <center>
                                <img src={sparkles} height="250vh" width="250vh" alt="sparkles international school logo" />
                                <Button variant="info" style={{ color: 'white', margin: '10px' }} onClick={handleShow} >DONT BUY CRYPTO</Button>
                            </center>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div style={{ height: '50vh', width: '50vh', margin: 10, padding: '10px' }}>
                            <center>
                                <img src={sparkles} height="250vh" width="250vh" alt="sparkles international school logo" />
                                <Button variant="info" style={{ color: 'white', margin: '30px' }} onClick={handleShow} >SMALLCASE IS THE CASE</Button>
                            </center>
                        </div>
                    </CarouselItem>
                </Carousel>

            </div>
            {/* <div style={{display: 'flex', flexFlow: 'row wrap', padding: '20px', justifyContent: 'space-around'}}>
                <div style={{ height: '50vh', width: '50vh', margin: 10, padding: '30px'}}>
                    <center>
                        <img src={sparkles} height="250vh" width="250vh" alt = "sparkles international school logo" />
                        <Button variant="info" style={{color: 'white', margin: '20px'}} onClick={handleShow} >LEARN MORE</Button>    
                    </center>
                </div>
                <div style={{ height: '50vh', width: '50vh', margin: 10, padding: '30px'}}>
                    <center>
                        <img src={sparkles} height="250vh" width="250vh" alt = "sparkles international school logo" />
                        <Button variant="info" style={{color: 'white', margin: '20px'}} onClick={handleShow} >LEARN MORE</Button>
                    </center>
                </div>
            </div> */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="info" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}