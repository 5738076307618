import React from 'react';
import '../../stylesheet/whatwedo.css';
import { Container, Row, Col } from 'reactstrap';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle,
} from 'reactstrap';
import ai from '../../assets/imgs/ai.jpg';
import webdev from '../../assets/imgs/web-dev.jpg';
import algorithm from '../../assets/imgs/algorithm.jpg';
export const WhatWeDo = () => {
    return (
        <div className="whtwedo-container">
            <Container>
                <Row>
                    <Col>
                        <h1 className="whtwedo-heading1">Services</h1>
                        <h4 className="whtwedo-subheading1">We are a platform for students by students which foster the culture of collaborating and growing with each other.</h4>
                    </Col>
                </Row>
                <Row >
                    <Col xs="12" sm="4" className="whtwedo-container-card">
                        <div >
                            <Card>
                                <CardImg top width="100%" src={ai} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle tag="h5">AI/ML MODELING</CardTitle>
                                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Subtitle</CardSubtitle> */}
                                    <CardText>We develop AI based chatbots and engines for analysis and automation.</CardText>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col xs="12" sm="4" className="whtwedo-container-card">
                        <div>
                            <Card>
                                <CardImg top width="100%" src={webdev} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle tag="h5">WEB/APP DEVELOPMENT</CardTitle>
                                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Subtitle</CardSubtitle> */}
                                    <CardText>We develop scalable business solutions for web and mobile apps.</CardText>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col xs="12" sm="4" className="whtwedo-container-card">
                        <div>
                            <Card>
                                <CardImg top width="100%" src={algorithm} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle tag="h5">DATA PREDICTION ALGORITHMS</CardTitle>
                                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted"> Subtitle</CardSubtitle> */}
                                    <CardText>We develop ML based prediction algorithms for forecasting , grouping and matching.</CardText>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}