import React, { useState } from 'react';
import { MenuItems } from './MenuItems';
import './NavBar.css';
// import logo from '../../assets/imgs/weconnect.jpeg';
import logo from '../../assets/imgs/logo.png';
export const NavBar = () => {

    const [click, handleClick] = useState(false);

    return (
        <nav className="NavbarItems">
            <h1 className="navbar-logo">
                {/* React
                <i className="fab fa-react"></i> */}
                <img src={logo} alt="weconnect logo" style={{width: 80}}/>
            </h1>
            <div className="menu-icon" onClick={() => handleClick(!click)}    >
                <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {MenuItems.map((i, idx) => {
                    return (
                        <li key={idx}>
                            <a className={i.cName} href={i.url}>
                                {i.title}
                            </a>
                        </li>
                    )
                })}
            </ul>
            {/* <Link to="/register">
                <Button color='primary'>
                    Register Now
                </Button>
            </Link> */}
        </nav>

    )
}
